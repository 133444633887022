<template>
  <div v-if="visible" class="after-challenge-popup-container">
    <div class="overlay"></div>
    <div class="popup">
      <div class="popup-header">
        <h1 v-if="$store.getters.isLoggedIn">
          Well done, {{ $store.state.user.username }}!
        </h1>
        <h1 v-if="!$store.getters.isLoggedIn">
          Well done!
        </h1>
        <p v-if="challengeData.tries === 1">
          You cracked this challenge in just
          <b>{{ getChallengeDurationString() }} minutes</b> and nailed it
          on your <b>first try</b>! 🚀
        </p>
        <p v-if="challengeData.tries !== 1">
          You cracked this challenge in just
          <b>{{ getChallengeDurationString() }} minutes</b> and it took
          you <b>{{ challengeData.tries }} attempts</b>! 🚀
        </p>
      </div>
      <div class="popup-body">
        <div class="ez-insert-here"></div>
        <div id="ezoic-pub-ad-placeholder-109"></div>
        <div id="humix-vid-ezAutoMatch" props="s=p&autoplay=1&controls=0&amo=0"></div>
        <div id="ezoic-pub-ad-placeholder-119"></div>
      </div>
      <div class="popup-footer">
        <div class="btn green" :class="{ disabled: timer > 0 }" @click="clickNextChallenge">
          {{ getButtonText(timer) }}
        </div>
        <span class="notice">
          <i>JSCodebox is free to use, so we have to sprinkle in some ads.
            <br />Thanks for putting up with them, you’re the best! </i>😊
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    challengeData: {
      type: Object,
      required: true,
    },
  },
  emits: ['next-challenge'],
  data() {
    return {
      timer: 0,
    };
  },
  watch: {
    visible() {
      if (this.visible) {

        // don't show ads if its a tutorial challenge
        if (this.isTutorialChallenge()) {
          this.clickNextChallenge();
          return;
        }

        this.setupAds();
        this.timer = this.getAdDuration(false);

        setInterval(() => {
          if (this.timer > 0) {
            this.timer--;
          }
        }, 1000);
      }
    },
  },
  methods: {
    setupAds() {
      // include external JS (async https://www.humix.com/video.js)
      const script = document.createElement('script');
      script.src = 'https://www.humix.com/video.js';
      script.async = true;
      document.body.appendChild(script);

      window.ezstandalone = window.ezstandalone || {};
      ezstandalone.cmd = ezstandalone.cmd || [];
      ezstandalone.cmd.push(function () {
        if (ezstandalone.enabled)
          ezstandalone.destroyPlaceholders(109, 119);
        // call new placeholders
        ezstandalone.define(109, 119);
        ezstandalone.refresh();
      });
    },
    isTutorialChallenge() {
      return this.challengeData.difficulty == 0;
    },
    clickNextChallenge(trackEvent = true) {
      if (this.timer > 0) return;

      if (trackEvent) {
        window._paq.push([
          'trackEvent',
          'Challenge',
          'Next challenge button',
          'AfterChallengeAd',
        ]);
      }

      this.$emit('next-challenge');
    },
    getChallengeDuration() {
      const start = new Date(this.challengeData.start);
      const end = new Date(this.challengeData.end);

      return end - start;
    },
    getChallengeDurationString() {
      const diff = this.getChallengeDuration();
      const seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      // add leading zero to seconds and minutes
      if (remainingSeconds < 10) remainingSeconds = `0${remainingSeconds}`;
      if (minutes < 10) minutes = `0${minutes}`;

      return `${minutes}:${remainingSeconds}`;
    },
    getButtonText(timer) {
      if (timer > 0) {
        return `Next challenge in ${timer} ...`;
      }
      return 'Start next challenge';
    },
    getAdDuration() {
      let duration = 15;
      const difficulty = this.challengeData.difficulty;

      // in error case
      if (!difficulty || difficulty == 0) return 0;

      // based on difficulty
      if (difficulty == 1) duration = 10;
      if (difficulty == 2) duration = 15;
      if (difficulty == 3) duration = 25;

      // less time for quick challenges
      if (this.getChallengeDuration() < 30000) duration = Math.round(duration / 2);

      return duration;
    }
  }
};
</script>
<style scoped>
.after-challenge-popup-container {
  position: relative;
}

.after-challenge-popup-container h1 {
  margin-bottom: 0;
}

.after-challenge-popup-container .overlay {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999990;
}

.after-challenge-popup-container .popup {
  max-width: 66%;
  max-height: 80vh;
  z-index: 999999991;
  display: flex;
  flex-direction: column;
}

.after-challenge-popup-container .popup-body {
  flex-grow: 1;
  margin: 3rem 0;
  min-height: 10rem;
}

.after-challenge-popup-container .popup-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.after-challenge-popup-container .popup-footer .notice {
  font-size: 80%;
  margin-top: 1rem;
  opacity: 0.6;
  text-align: center;
}
</style>
