<template>
  <div class="bottom-container sticky">
    <div class="bottom-inner">
      <div class="left">
        <div class="button-with-text">
          <span
            v-if="!success"
            class="btn small"
            title="Saves and executes your code"
            @click="$emit('run-code', true)"
          >Run Code!</span>
          <span
            v-if="!success && showSolution"
            class="small-txt"
            title="Replaces your code with the solution. Use this only if you really can't get any further."
            @click="pasteSolution"
          >or paste solution</span>
        </div>
        <span v-if="success && !loadingNext" class="btn" @click="next">Next challenge</span>
        <!-- eslint-disable-next-line -->
        <span class="loader" v-if="success && loadingNext"></span>

        <span
          class="settings-button"
          title="Open settings"
          @click="showSettings = true"
        ><img src="@/assets/img/settings.svg" /></span>
      </div>

      <div class="right">
        <router-link
          :to="'/bug-report?challengeID=' + challenge.id"
          class="report-button"
          title="Report a bug"
        >
          <img src="@/assets/img/flag.svg" alt="flag" />
        </router-link>
      </div>
    </div>

    <ChallengeSettings
      :show-settings="showSettings"
      @close-settings="showSettings = false"
      @change-theme="changeTheme"
      @change-editor-layout="changeEditorLayout"
    />

    <p
      v-if="notification.msg"
      class="notification"
      :class="{
        green: notification.color == 'green',
        orange: notification.color == 'orange',
        red: notification.color == 'red',
      }"
      v-html="notification.msg"
    />
  </div>
</template>
<script>
import ChallengeService from '@/services/ChallengeService.js';

import ChallengeSettings from '@/components/challenge/ChallengeSettings.vue';

export default {
  components: { ChallengeSettings },
  props: {
    challenge: {
      type: Object,
      required: true,
    },
    editorLayout: {
      type: String,
      required: true,
    },
    success: {
      type: Boolean,
      default: false,
    },
    showSolution: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Object,
      default: () => {
        return {
          msg: '',
          color: '',
        };
      },
    },
  },
  emits: [
    'run-code',
    'change-theme',
    'change-editor-layout',
    'next-challenge-clicked',
  ],
  data() {
    return {
      showSettings: false,
      loadingNext: false, // if the next challenge is loading
    };
  },
  methods: {
    changeTheme(val) {
      this.$emit('change-theme', val);
    },
    changeEditorLayout(val) {
      this.$emit('change-editor-layout', val);
    },

    next: async function () {
      this.$emit('next-challenge-clicked', true);
      return;
    },
  },
};
</script>
<style scoped>
.btn {
  margin: 1rem 0;
  display: inline-block;
}
.bottom-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-container.sticky {
  position: sticky;
  bottom: 1rem;
  background-color: var(--light-grey);
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  z-index: 9999999;
}
.bottom-container .bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.bottom-container .bottom-inner > div {
  display: flex;
  align-items: center;
}
.bottom-container .settings-button {
  margin-left: 1rem;
}
.bottom-container .report-button {
  margin-right: 0.5rem;
}
.bottom-container .settings-button img,
.bottom-container .report-button img {
  width: 1.2rem;
  height: 1.2rem;
  opacity: 0.5;
  transition: 0.2s ease all;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  cursor: pointer;
}

.bottom-container .settings-button img:hover,
.bottom-container .report-button img:hover {
  opacity: 1;
}
@keyframes popup {
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.01);
  }
  80% {
    transform: scale(1);
  }
}
.notification {
  border: 2px solid var(--grey);
  color: var(--grey);
  padding: 5px 10px;
  font-size: 1.4rem;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
}
</style>
